<template>
  <div class="circular-progress">
    <svg :width="size" :height="size">
      <circle
        :cx="radius"
        :cy="radius"
        :r="innerRadius"
        :stroke="trackColor"
        :stroke-width="strokeWidth"
        fill="none"
      />
      <circle
        :cx="radius"
        :cy="radius"
        :r="innerRadius"
        :stroke="progressColor"
        :stroke-width="strokeWidth"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="dashOffset"
        fill="none"
      />
    </svg>
    <div class="progress-label">{{ progress }}%</div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 100,
    },
    strokeWidth: {
      type: Number,
      default: 10,
    },
    progress: {
      type: Number,
      default: 0,
    },
    trackColor: {
      type: String,
      default: "#ccc",
    },
    progressColor: {
      type: String,
      default: "#0097B2",
    },
  },
  computed: {
    radius() {
      return this.size / 2;
    },
    innerRadius() {
      return this.radius - this.strokeWidth / 2;
    },
    circumference() {
      return 2 * Math.PI * this.innerRadius;
    },
    dashOffset() {
      return ((100 - this.progress) / 100) * this.circumference;
    },
  },
};
</script>

<style scoped>
.circular-progress {
  display: inline-block;
  position: relative;
}

.progress-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  color: #333;
}

</style>
